<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Hilton Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#1d3091': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#191f25': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#84235d': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#d23476': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/hilton/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/hilton/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/hilton/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/hilton/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#491435' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white hilton-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              logoPosition: 'left', // left or center
              menuPosition: 'right', // right or center
              backgroundClass: 'bg-[#84235d]',
              mobileMenuBackground: 'bg-[#84235d]',
              bookNowBackground: 'bg-white',
              bookNowText: 'text-[#491435]',
              mobileMenuForeground: 'text-white',
              mobileMenuBookingForeground: 'color: #491435',
              mobileMenuBookingBackground: 'background-color: #fff',
              textClass: 'text-white',
              languageSelectorText: 'text-white',
              languageSelectorBg: 'bg-grey',
              shiftOnScroll: {
                enabled: true,
                offset: 100,
                logoWidth: 100,
              },
              paper: false,
            }"
          >
            <template #logo>
              <img v-if="currentResort.data.logo" :src="currentResort.data.logo" :alt="`${currentResort.data.name} Logo`" class="w-32">
              <LogosHilton v-else :fill="false" class="text-white w-24" />
            </template>
            <template #mobilelogo>
              <img v-if="currentResort.data.logo" :src="currentResort.data.logo" :alt="`${currentResort.data.name} Logo`" class="w-32">
              <LogosHilton v-else :fill="false" class="text-white w-24" />
            </template>
          </NavigationHeader>

          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :config="{
              crossPromotion: false,
              signUpForm: false,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/hilton.scss';
</style>
